import * as React from 'react';

import SvgIcon, {SVGIconProps} from './svgIcon';

const IconReceipts = React.forwardRef<SVGSVGElement, SVGIconProps>((props, ref) => {
    return (
        <SvgIcon {...props} ref={ref}>
            <path d="M53 59v-4h-8c-1.1 0-2-.9-2-2v-8c0-1.1.9-2 2-2h3v-4c0-1.1.9-2 2-2s2 .9 2 2v4h3c1.1 0 2 .9 2 2s-.9 2-2 2h-8v4h8c1.1 0 2 .9 2 2v8c0 1.1-.9 2-2 2h-3v4c0 1.1-.9 2-2 2s-2-.9-2-2v-4h-3c-1.1 0-2-.9-2-2s.9-2 2-2h8zm42-46v26c0 1.1-.9 2-2 2H75V83.3c0 .1 0 .1-.1.2 0 .1 0 .1-.1.2 0 .1-.1.1-.1.2s-.1.1-.1.2-.1.1-.1.2-.1.1-.1.1l-.2.2-.1.1-5.8 4h-.1c-.1.1-.2.1-.4.2-.1 0-.1 0-.2.1-.2 0-.3.1-.5.1s-.3 0-.5-.1c-.1 0-.1 0-.2-.1-.1 0-.2-.1-.4-.2h-.1l-4.6-3.2-4.6 3.2h-.1c-.1.1-.2.1-.4.2-.1 0-.1 0-.2.1-.2 0-.3.1-.5.1s-.3 0-.5-.1c-.1 0-.1 0-.2-.1-.1 0-.2-.1-.4-.2h-.1L50 85.4l-4.6 3.2h-.1c-.1.1-.2.1-.4.2-.1 0-.1 0-.2.1-.2 0-.3.1-.5.1s-.3 0-.5-.1c-.1 0-.1 0-.2-.1-.1 0-.2-.1-.4-.2H43l-4.6-3.2-4.6 3.2h-.1c-.1.1-.2.1-.4.2-.1 0-.1 0-.2.1-.2 0-.3.1-.5.1s-.3 0-.5-.1c-.1 0-.1 0-.2-.1-.1 0-.2-.1-.4-.2h-.1l-5.7-4-.1-.1-.2-.2-.1-.1c0-.1-.1-.1-.1-.2s-.1-.1-.1-.2-.1-.1-.1-.2 0-.1-.1-.2c0-.1 0-.1-.1-.2V41H7c-1.1 0-2-.9-2-2V13c0-1.1.9-2 2-2H92.8c1.3 0 2.2.9 2.2 2zM29 25v57l3.8 2.6 4.6-3.2c.1 0 .1-.1.2-.1l.1-.1c.1 0 .1 0 .2-.1h1c.1 0 .1 0 .2.1.1 0 .1 0 .2.1.1 0 .1 0 .1.1.1 0 .1.1.2.1l4.6 3.2 4.6-3.2c.1 0 .1-.1.2-.1l.1-.1c.1 0 .1 0 .2-.1h1c.1 0 .1 0 .2.1.1 0 .1 0 .2.1.1 0 .1 0 .1.1.1 0 .1.1.2.1l4.6 3.2 4.6-3.2c.1 0 .1-.1.2-.1s.1-.1.2-.1.1 0 .2-.1c.1 0 .1 0 .2-.1h.8c.1 0 .1 0 .2.1.1 0 .1 0 .2.1.1 0 .1.1.2.1s.1.1.2.1l4.6 3.2L71 82V25H29zm46 12h16V15H9v22h16V25h-6v4c0 1.1-.9 2-2 2s-2-.9-2-2v-6c0-1.1.9-2 2-2h66c1.1 0 2 .9 2 2v6c0 1.1-.9 2-2 2s-2-.9-2-2v-4h-6v12z"></path>
        </SvgIcon>
    );
});

IconReceipts.displayName = 'IconReceipts';

export {IconReceipts};
