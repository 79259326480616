import React from 'react';
import InputField from 'Shared/form/Input';
import {AxiosResponse, AxiosError} from 'axios';
import ButtonField from 'Shared/form/Button';
import LoginLayout from 'Shared/layout/Login';
import FieldContainer from 'Shared/_partials/FieldContainer';
import Label from 'Shared/form/Label';
import LoginHeader from 'Shared/header/Login';
import {Routes} from 'Utilites/routes';
import {useAuth} from 'hooks/useAuth';
import {Navigate} from 'react-router-dom';
import apiClient from 'services/api';
import styled from '@emotion/styled';

/* Query String */
import * as qs from 'query-string';

import {Loading} from 'Shared/feedback/Loading';
const Paragraph = styled.p``;

const Login = () => {
    // TODO: improve code for the role base authentication and authorization
    const adminRedirect = `/dashboard`;
    const urlRedirect = `/registers`;

    const {user, login} = useAuth();

    if (user && user.type === `administrator`) {
        return <Navigate to={adminRedirect} />;
    }
    if (user && user.type === `cashier`) {
        return <Navigate to={urlRedirect} />;
    }

    const [username, setUsername] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);

    const [returnMessage, setReturnMessage] = React.useState<string>('');
    const [returnType, setReturnType] = React.useState<string>('4999');

    const handleAuthentication = (event: any) => {
        event.preventDefault();
        const queryStrings = qs.parse(location.search);
        /**
         * Let's connect to the sanctum API
         */
        setLoading(true);
        apiClient.get(Routes.login.token).then(response => {
            apiClient
                .post(Routes.login.auth, {
                    email: username,
                    password: password,
                })
                .then((response: AxiosResponse) => {
                    let navigateTo: string =
                        response.data.type === `administrator`
                            ? adminRedirect
                            : urlRedirect;
                    if (Object.keys(queryStrings).length !== 0) {
                        if (queryStrings.redirect_to !== null) {
                            navigateTo = queryStrings.redirect_to.toString();
                        }
                    }
                    if (response.status === 200) {
                        login(response.data, navigateTo);
                    }
                })
                .catch((error: AxiosError) => {
                    if (error.request.status === 422) {
                        setReturnType(`422`);
                        setReturnMessage(
                            `The provided credentials do not match our records.`
                        );
                        setLoading(false);
                    }else{
                        setLoading(false);
                    }
                });
        });
    };

    return (
        <React.Fragment>
            {loading ? <Loading /> : <></>}
            <LoginLayout>
                <LoginHeader />
                <FieldContainer>
                    <Label>Username</Label>
                    <InputField
                        value={username}
                        onChange={(e: any) => setUsername(e.target.value)}
                    />
                </FieldContainer>
                <FieldContainer>
                    <Label>Password</Label>
                    <InputField
                        type="password"
                        value={password}
                        onChange={(e: any) => setPassword(e.target.value)}
                    />
                </FieldContainer>
                {returnMessage !== `` ? (
                    <FieldContainer>
                        <Paragraph
                            className={`message ${
                                returnType === `422` ? 'error' : 'success'
                            }`}
                        >
                            {returnMessage}
                        </Paragraph>
                    </FieldContainer>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
                <FieldContainer>
                    <ButtonField onClick={handleAuthentication} disabled={loading}>
                        {loading ? `Processing` : `Login`}
                    </ButtonField>
                </FieldContainer>
            </LoginLayout>
        </React.Fragment>
    );
};

export default Login;
