import React from "react";

export interface SVGIconProps extends React.SVGAttributes<SVGSVGElement>{
    className?: string,
    color?: string,
    size?: string
}

const SvgIcon = React.forwardRef<SVGElement, SVGIconProps>(function SvgIcon({
    color: providedColor = 'currentColor',
    size: providedSize = 'sm',
    viewBox = '0 0 16 16',
    ...props
}, ref
){
    const color = providedColor
    const size = providedSize

    return (
        <svg {...props} viewBox={viewBox} fill={color} height={size} width={size} ref={ref} />
    )
})

export default SvgIcon