import * as React from 'react';

import SvgIcon, {SVGIconProps} from './svgIcon';

const IconOrders = React.forwardRef<SVGSVGElement, SVGIconProps>((props, ref) => {
    return (
        <SvgIcon {...props} ref={ref}>
            <path d="M40.14,14.46,30.8,4.9A3,3,0,0,0,28.66,4H14a3,3,0,0,0-3,3V8H10a3,3,0,0,0-3,3v5a1,1,0,0,0,2,0V11a1,1,0,0,1,1-1h1V37a3,3,0,0,0,3,3H35v1a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V20a1,1,0,0,0-2,0V41a3,3,0,0,0,3,3H34a3,3,0,0,0,3-3V40h1a3,3,0,0,0,3-3V16.55A2.93,2.93,0,0,0,40.14,14.46ZM31,8l6.88,7H32a1,1,0,0,1-1-1Zm8,29a1,1,0,0,1-1,1H14a1,1,0,0,1-1-1V7a1,1,0,0,1,1-1H28.66a1,1,0,0,1,.34.07V14a3,3,0,0,0,3,3h7ZM32.63,20.22a1,1,0,0,1,.15,1.41l-8,10A1,1,0,0,1,24,32a1,1,0,0,1-.62-.22l-5-4a1,1,0,0,1-.16-1.4,1,1,0,0,1,1.41-.16l4.21,3.37,7.38-9.21A1,1,0,0,1,32.63,20.22Z"></path>
        </SvgIcon>
    );
});

IconOrders.displayName = 'IconOrders';

export {IconOrders};
