// TODO: use query-string to generate URL

import React from 'react';
import {Link} from 'react-router-dom';
import tw from 'twin.macro';
import styled from '@emotion/styled';
import LoggedInUser from '../partials/user';

import {useAuth} from 'hooks/useAuth';

/*
 * ICONS
 */
import {IconDashboard} from 'Shared/icons/iconDashboard';

import {IconAnalytics} from 'Shared/icons/iconAnalytics';
import {IconArrowRight} from 'Shared/icons/iconArrowRight';
import {IconPrintingLabel} from 'Shared/icons/iconPrintingLabel';
import {IconCustomers} from 'Shared/icons/iconCustomers';
import {IconUserAlt} from 'Shared/icons/iconUserAlt';
import {IconReward} from 'Shared/icons/iconReward';
import {IconSettings} from 'Shared/icons/iconSettings';
import {IconGoodsOrders} from 'Shared/icons/iconGoodsOrders';
import {IconSuppliers} from 'Shared/icons/iconSuppliers';

import {IconDashboardNew} from 'Shared/icons/iconDashboardNew';
import {IconOrders} from 'Shared/icons/iconOrders';
import {IconProducts} from 'Shared/icons/iconProducts';
import {IconOutlets} from 'Shared/icons/iconOutlets';
import {IconCoupons} from 'Shared/icons/iconCoupons';
import {IconReceipts} from 'Shared/icons/iconReceipts';
import {IconRegisters} from 'Shared/icons/iconRegisters';
import {IconTaxes} from 'Shared/icons/iconTaxes';

/*
 * Style
 */
const Container = tw.div`flex flex-col justify-between h-full overflow-y-auto`;
const NavigationWrapper = tw.nav`flex`;
const ListWrapper = tw.div`flex flex-col w-full`;
const LinkListItem = styled(Link)`
    ${tw`flex items-center w-full px-4 py-3 rounded-md hover:(bg-slate-200)`}
`;
const LinkListItemChild = styled(LinkListItem)`
    ${tw`pl-9`}
`;
const UserWrapper = tw.div`flex flex-col`;
const IconWrap = tw.span`mr-2`;
const TextWrap = tw.span``;
const ArrowIconWrap = tw(IconWrap)`mr-0 ml-auto`;

const NavigationItemWrapper = tw.div`relative flex items-center px-4 py-3 cursor-pointer justify-start rounded-md hover:(bg-slate-200)`;
const NavigationItemChildren = tw.div`overflow-hidden h-0`;

const menus = [
    {
        label: 'Dashboard',
        url: '/dashboard',
        icon: <IconDashboardNew viewBox="0 0 24 24" size="24" />,
        type: ['administrator'],
    },
    {
        label: 'Products',
        url: null,
        icon: <IconProducts viewBox="0 0 24 24" size="24" />,
        type: ['administrator'],
        children: [
            {
                label: 'All Products',
                url: 'products',
                icon: null,
                type: ['administrator'],
            },
            {
                label: 'Add New',
                url: 'products/new',
                icon: null,
                type: ['administrator'],
            },
            {
                label: 'Categories',
                url: 'categories',
                icon: null,
                type: ['administrator'],
            },
            {
                label: 'Attributes',
                url: 'products/attributes',
                icon: null,
                type: ['administrator'],
            },
            {
                label: `Additional charges`,
                url: `products/additional-charges`,
                icon: null,
                type: ['administrator'],
            },
        ],
    },
    {
        label: 'Outlets',
        url: null,
        icon: <IconOutlets viewBox="0 0 24 24" size="24" />,
        type: ['administrator'],
        children: [
            {
                label: 'All Outlets',
                url: 'outlets',
                icon: null,
                type: ['administrator'],
            },
            {
                label: 'Add New',
                url: 'outlets/new',
                icon: null,
                type: ['administrator'],
            },
        ],
    },
    {
        label: 'Coupons',
        url: null,
        icon: <IconCoupons viewBox="0 0 24 24" size="24" />,
        type: ['administrator'],
        children: [
            {
                label: 'All Coupons',
                url: 'coupons',
                icon: null,
                type: ['administrator'],
            },
            {
                label: 'Add New',
                url: 'coupons/new',
                icon: null,
                type: ['administrator'],
            },
        ],
    },
    {
        label: 'Receipts',
        url: null,
        icon: <IconReceipts viewBox="0 0 100 100" size="24" />,
        type: ['administrator'],
        children: [
            {
                label: 'All Receipts',
                url: 'receipts',
                icon: null,
                type: ['administrator'],
            },
            {
                label: 'Add New',
                url: 'receipts/new',
                icon: null,
                type: ['administrator'],
            },
        ],
    },
    {
        label: 'Registers',
        url: null,
        icon: <IconRegisters viewBox="0 0 512 512" size="24" />,
        type: ['administrator', 'cashier'],
        children: [
            {
                label: 'All Registers',
                url: 'registers',
                icon: null,
                type: ['administrator', 'cashier'],
            },
            {
                label: 'Add New',
                url: 'registers/new',
                icon: null,
                type: ['administrator'],
            },
        ],
    },
    {
        label: 'Taxes',
        url: null,
        icon: <IconTaxes viewBox="0 0 512 512" size="24" />,
        type: ['administrator'],
        children: [
            {
                label: 'All Taxes',
                url: 'taxes',
                icon: null,
                type: ['administrator'],
            },
            {
                label: 'Add New',
                url: 'taxes/new',
                icon: null,
                type: ['administrator'],
            },
        ],
    },
    {
        label: 'Orders',
        url: '/orders',
        icon: <IconOrders viewBox="0 0 48 48" size="24" />,
        type: ['administrator'],
    },
    {
        label: `Customers`,
        url: `/customers`,
        icon: <IconCustomers viewBox="0 0 24 24" size="24" />,
        type: ['administrator', 'cashier'],
    },
    {
        label: `Staff`,
        url: `/users`,
        icon: <IconUserAlt viewBox="0 0 24 24" size="24" />,
        type: ['administrator'],
    },
    {
        label: `Suppliers`,
        url: `/suppliers`,
        icon: <IconSuppliers viewBox="0 0 24 24" size="24" />,
        type: ['administrator'],
    },
    {
        label: `Goods Orders`,
        url: `/goods-orders`,
        icon: <IconGoodsOrders viewBox="0 0 24 24" size="24" />,
        type: ['administrator'],
    },
    {
        label: `Labels`,
        url: `/printing-labels`,
        icon: <IconPrintingLabel viewBox="0 0 24 24" size="19" />,
        type: ['administrator'],
    },
    {
        label: `Rewards`,
        url: `/rewards`,
        icon: <IconReward viewBox="0 0 24 24" size="24" />,
        type: ['administrator'],
    },
    {
        label: `Settings`,
        url: null,
        icon: <IconSettings viewBox="0 0 24 24" size="24" />,
        type: ['administrator'],
        children: [
            {
                label: 'Rewards',
                url: '/settings/rewards?tab=points',
                icon: null,
                type: ['administrator'],
            },
            // {
            //     label: 'Products',
            //     url: '/settings/products',
            //     icon: null,
            //     type: ['administrator'],
            // },
        ],
    },
    {
        label: 'Reports',
        url: '/analytics',
        icon: <IconAnalytics viewBox="0 0 24 24" size="19" />,
        type: ['administrator'],
        children: [
            {
                label: 'Revenue',
                url: '/analytics/?path=/analytics/revenue',
                icon: null,
                type: ['administrator'],
            },
            {
                label: 'Orders',
                url: '/analytics/?path=/analytics/orders',
                icon: null,
                type: ['administrator'],
            },
            {
                label: 'Products',
                url: '/analytics/?path=/analytics/products',
                icon: null,
                type: ['administrator'],
            },
            {
                label: 'Taxes',
                url: '/analytics/?path=/analytics/taxes',
                icon: null,
                type: ['administrator'],
            },
            {
                label: 'Stock',
                url: '/analytics/?path=/analytics/stock',
                icon: null,
                type: ['administrator'],
            },
            {
                label: 'Profits',
                url: '/analytics/?path=/analytics/profits',
                icon: null,
                type: ['administrator'],
            },
            {
                label: 'Refunds',
                url: '/analytics/?path=/analytics/refunds',
                icon: null,
                type: ['administrator'],
            },
            {
                label: 'Inventory',
                url: '/analytics/?path=/analytics/inventory',
                icon: null,
                type: ['administrator'],
            },
        ],
    },
];

const Sidebar = () => {
    const {user, logout} = useAuth();
    /** set to 200 initially as there won't be any index that reaches to this level */
    const [activeNavigation, setActiveNavigation] = React.useState<number>(200);

    React.useEffect(() => {}, [activeNavigation]);

    const handleNavigationClick = (index: number) => {
        activeNavigation === index
            ? setActiveNavigation(200)
            : setActiveNavigation(index);
    };

    return (
        <React.Fragment>
            <Container>
                <NavigationWrapper>
                    <ListWrapper>
                        {menus.map((menu, index) =>
                            menu.type.includes(user.type) ? (
                                menu.children === null || menu.children === undefined ? (
                                    <React.Fragment key={index}>
                                        <LinkListItem to={menu.url}>
                                            <IconWrap>{menu.icon}</IconWrap>
                                            <TextWrap>{menu.label}</TextWrap>
                                        </LinkListItem>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment key={index}>
                                        <NavigationItemWrapper
                                            onClick={() => handleNavigationClick(index)}
                                        >
                                            <IconWrap>{menu.icon}</IconWrap>
                                            <TextWrap>{menu.label}</TextWrap>
                                            <ArrowIconWrap>
                                                <IconArrowRight
                                                    style={{
                                                        transform:
                                                            activeNavigation === index
                                                                ? 'rotate(90deg)'
                                                                : 'initial',
                                                    }}
                                                    viewBox="0 0 24 24"
                                                    size="24"
                                                />
                                            </ArrowIconWrap>
                                        </NavigationItemWrapper>
                                        <NavigationItemChildren
                                            style={{
                                                height:
                                                    activeNavigation === index
                                                        ? 'auto'
                                                        : '0px',
                                            }}
                                        >
                                            {menu.children.map((mchild, index) =>
                                                mchild.type.includes(user.type) ? (
                                                    <LinkListItemChild
                                                        to={mchild.url}
                                                        key={index}
                                                    >
                                                        <IconWrap>{mchild.icon}</IconWrap>
                                                        <TextWrap>
                                                            {mchild.label}
                                                        </TextWrap>
                                                    </LinkListItemChild>
                                                ) : (
                                                    <React.Fragment
                                                        key={index}
                                                    ></React.Fragment>
                                                )
                                            )}
                                        </NavigationItemChildren>
                                    </React.Fragment>
                                )
                            ) : (
                                <React.Fragment key={index}></React.Fragment>
                            )
                        )}
                    </ListWrapper>
                </NavigationWrapper>
                <UserWrapper>
                    <LoggedInUser></LoggedInUser>
                </UserWrapper>
            </Container>
        </React.Fragment>
    );
};

export default Sidebar;
