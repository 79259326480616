import { useState } from "react"

export const useLocalStorage = (key:string, defaultValue:string|[]|null) => {
    const [storeValue, setStoreValue] = useState(() => {
        try{
            const value = window.localStorage.getItem(key)
            if(value){
                return JSON.parse(value)
            } else{
                window.localStorage.setItem(key, JSON.stringify(defaultValue))
                return defaultValue
            }
        } catch(err){
            return defaultValue
        }
    })

    const setValue = (newValue:string) => {
        try{
            window.localStorage.setItem(key, JSON.stringify(newValue));
        } catch (err) {}
        setStoreValue( newValue )
    }

    return [storeValue, setValue]
}