import tw, {styled} from "twin.macro"
import { css } from '@emotion/react'

interface StyledButtonProps {
    isLoading?: boolean;
    disabled?: boolean;
}

const primaryButton = css`
    ${tw`bg-indigo-600 uppercase font-semibold tracking-wider py-3 px-8 text-white hover:bg-indigo-700`}
`

export const Button = styled.button<StyledButtonProps>`
    ${primaryButton}
`