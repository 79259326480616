import React from 'react';
import styled from '@emotion/styled';

const LabelContainer = styled.label``;

type LabelContainerPros = {
    children?: React.ReactNode;
    for?: string;
    style?: React.CSSProperties;
};

const Label = ({children, style}: LabelContainerPros) => {
    return <LabelContainer style={style}>{children}</LabelContainer>;
};

export default Label;
