import React from "react"
import ReactDOM from "react-dom"

import styled from "@emotion/styled"

const Wrapper = styled.div``
const Content = styled.div``

const Loading = (  ) => {
    const loading = (
        <React.Fragment>
            <Wrapper className="h-l-wrap"><Content className="h-l-bg"></Content></Wrapper>
        </React.Fragment>
    )

    return ReactDOM.createPortal(loading, document.body)
}

export { Loading }