import React from "react"
import { useAuth } from "hooks/useAuth"

import { Routes } from "Utilites/routes"
import apiClient from "services/api"

// TODO: aesthetic changes in here

const LoggedInUser = () =>{

    const { user, logout } = useAuth()

    const handleLogout = (event:any) => {
        event.preventDefault()
        apiClient.post(Routes.logout).then( res => {
            logout()
        } ).catch( err => {
            console.log( err )
            if( err.response.status === 401 ){
                logout()
            }
        } )
    }

    return (
        <>
            <div><button onClick={handleLogout}>Logout</button></div>
            <br />
            <span style={{display: "block"}}>{user.username}</span>
        </>
    )
}

export default LoggedInUser