import React from 'react';
import {Outlet, Navigate} from 'react-router-dom';
import Sidebar from './sidebar';
import {useAuth} from 'hooks/useAuth';
import {QueryClient, QueryClientProvider} from 'react-query';
import tw from 'twin.macro';

const Container = tw.div`flex w-full ml-auto mr-auto`;

const SidebarC = tw.div`h-screen bg-slate-100 border-r border-slate-300 sticky px-4 py-4 max-w-[240px] min-w-[240px] w-[240px] overflow-hidden`;

const Content = tw.div`w-full h-screen overflow-y-auto relative overflow-x-hidden`;

const Wrapper = () => {
    const queryClient = new QueryClient();

    const {user} = useAuth();

    if (!user) return <Navigate to="/login" />;

    return (
        <Container>
            <SidebarC>
                <Sidebar />
            </SidebarC>
            <Content id="app-content">
                <QueryClientProvider client={queryClient}>
                    <Outlet />
                </QueryClientProvider>
            </Content>
        </Container>
    );
};

export default Wrapper;
