import React from "react"

import tw, { styled } from "twin.macro"

const HeaderContainer = styled.div`
    ${tw`flex flex-col justify-center items-center`}
`
const Heading = styled.h3`
    ${tw`font-semibold text-4xl mt-4 mb-8`}
`

const LoginHeader = () => {
    return (
        <>
            <HeaderContainer>
                <img src={`https://abtechsolutions.ca/wp-content/uploads/2019/02/abtechsolutions-email-logo.png`} alt={`ABTech Solutions`} />
                <Heading>{`Welcome back!`}</Heading>
            </HeaderContainer>
        </>
    )
}

export default LoginHeader
