import React, { createContext, useContext } from "react"
import { PersonifyStore } from "cashstores/personifyStore"
import { useLocalObservable } from 'mobx-react'

const PersonifyContext = createContext(null)

type Props = {
    children: React.ReactNode
}

export const PersonifyProvider = ({children}: Props) => {
    const personfiyStore = useLocalObservable(PersonifyStore)

    return (
        <PersonifyContext.Provider value={personfiyStore}>{children}</PersonifyContext.Provider>
    )
}

export const usePersonifyStore = () => useContext(PersonifyContext)