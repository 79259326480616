/**
 * Used to determine browser local storage changes
 * Mostly used for screen options feature
*/

export const PersonifyStore = () => {

    return {
        screen: {
            options: {
                products: ""
            }
        },
        getProductsScreenOptions() {
            const _value = this.getLocalStore( "product_options" )
            if( _value ){
                return JSON.parse( _value )
            }else{
                // TODO:
                this.setProductsScreenOptions( ["title", "sku", "barcode", "categories", "stock", "price"] )
            }
        },
        setProductsScreenOptions( _options: Array<string> ) {
            this.setLocalStore( "product_options", _options.join(",") )
        },
        getLocalStore( _key:string ){
            return window.localStorage.getItem( _key )
        },
        setLocalStore( _key:string, _value:string ){
            window.localStorage.setItem(_key, JSON.stringify(_value))
        }
    }
}
