import React, { ComponentPropsWithoutRef, ElementType } from "react";
import * as Styled from './Button.Style'

type ButtonPros<T extends ElementType> = {
    renderAs?: T;
    isLoading?: boolean;
    disabled?: boolean;
    children?: React.ReactNode
} & ComponentPropsWithoutRef<T>

const ButtonField = <T extends ElementType = 'button'>({
    renderAs,
    isLoading,
    children,
    ...rest
}: ButtonPros<T>): JSX.Element => {
    return (
        <Styled.Button {...rest} as={renderAs}>
            {children}
        </Styled.Button>
    )
}


export default ButtonField;
