import * as React from 'react';

import SvgIcon, {SVGIconProps} from './svgIcon';

const IconPrintingLabel = React.forwardRef<SVGSVGElement, SVGIconProps>((props, ref) => {
  return (
    <SvgIcon {...props} ref={ref}>
        <path d="M10.773 21.585l-1.368 1.415-10.405-10.429v-8.571h2v7.719l9.773 9.866zm1.999-20.585h-9.772v9.772l12.074 12.228 9.926-9.85-12.228-12.15zm-4.772 7c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2z" />
    </SvgIcon>
  );
});

IconPrintingLabel.displayName = 'IconPrintingLabel';

export {IconPrintingLabel};
