import styled from '@emotion/styled';
import {css} from '@emotion/react';

interface StyledInputProps {
    isDisabled?: boolean;
}

const defaultStyle = css`
    z-index: 5;
    transition: padding 0.1s linear;
`;

const loadingStyle = css`
    position: absolute;
    z-index: 10;
`;

export const InputContainer = styled.div`
    display: flex;
    position: relative;
    width: 100%;
`;

export const Input = styled.input<StyledInputProps>`
    ${defaultStyle}
`;

export const InputLoading = styled.div`
    ${loadingStyle}
`;
