import * as React from 'react';

import SvgIcon, {SVGIconProps} from './svgIcon';

const IconAnalytics = React.forwardRef<SVGSVGElement, SVGIconProps>((props, ref) => {
  return (
    <SvgIcon {...props} ref={ref}>
        <path d="M5 19h-4v-4h4v4zm6 0h-4v-8h4v8zm6 0h-4v-13h4v13zm6 0h-4v-19h4v19zm1 2h-24v2h24v-2z"/>
    </SvgIcon>
  );
});

IconAnalytics.displayName = 'IconAnalytics';

export {IconAnalytics};
