import * as React from 'react';

import SvgIcon, {SVGIconProps} from './svgIcon';

const IconClear = React.forwardRef<SVGSVGElement, SVGIconProps>((props, ref) => {
    return (
        <SvgIcon {...props} ref={ref}>
            {/* <path d="M29.3193,24.6152,28.3957,8.752a1,1,0,0,1,.9985-1.0547h5.2116a1,1,0,0,1,.9985,1.0547l-.9236,15.8632Zm14.8863,3H19.7944a6.5294,6.5294,0,0,0-6.5219,6.522V36.144h37.455V34.1372A6.5294,6.5294,0,0,0,44.2056,27.6152ZM13.2725,56.3027h6.5268V49.8638a1.5,1.5,0,0,1,3,0v6.4389H35.1255V45.0313a1.5,1.5,0,0,1,3,0V56.3027h3.6274V50.83a1.5,1.5,0,0,1,3,0v5.4726h5.9746V39.144H13.2725Z" /> */}
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </SvgIcon>
    );
});

IconClear.displayName = 'IconClear';

export {IconClear};
