import axios from 'axios';

const apiClient = axios.create({
    baseURL: `/`,
    withCredentials: true,
});

apiClient.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        // if unauthorized or logged out from server end make sure user is redirected to login page
        if (error.response.status === 401) {
            window.localStorage.setItem('user', null);
            location.href = `/login`; // redirect to login
        } else {
            return Promise.reject(error);
        }
    }
);

export default apiClient;
