import * as React from 'react';

import SvgIcon, {SVGIconProps} from './svgIcon';

const IconRegisters = React.forwardRef<SVGSVGElement, SVGIconProps>((props, ref) => {
    return (
        <SvgIcon {...props} ref={ref}>
            <path
                d="M476.3,405.96h-3.95V226.14c0-24.95-20.3-45.24-45.25-45.24h-39.15v-4.55h47.27c8.57,0,15.54-6.97,15.54-15.54V75.22
	c0-8.57-6.97-15.54-15.54-15.54H288.23c-8.57,0-15.54,6.97-15.54,15.54v85.58c0,8.57,6.97,15.54,15.54,15.54h47.27v4.55h-70.08
	v-22.54c0-3.87-3.13-7-7-7h-18.6V19.62c0-3.09-2.03-5.82-4.99-6.71c-2.96-0.89-6.16,0.28-7.86,2.86l-5.75,8.75l-8.13-12.37
	c-1.29-1.97-3.49-3.16-5.85-3.16s-4.55,1.19-5.85,3.15l-8.13,12.38l-8.13-12.38c-1.29-1.97-3.49-3.15-5.85-3.15
	s-4.55,1.19-5.85,3.15l-8.13,12.38l-8.13-12.38C155.86,10.19,153.66,9,151.3,9s-4.55,1.19-5.85,3.16l-8.13,12.37l-8.13-12.37
	c-1.29-1.97-3.49-3.15-5.85-3.15s-4.55,1.19-5.85,3.15l-8.13,12.38l-5.76-8.75c-1.7-2.58-4.9-3.75-7.86-2.86
	c-2.96,0.89-4.99,3.61-4.99,6.71v131.73h-18.6c-3.87,0-7,3.13-7,7v27.11c-15.44,7.52-25.5,23.29-25.5,40.68v179.83H35.7
	c-13.4,0-24.29,10.9-24.29,24.29v48.45c0,13.4,10.9,24.3,24.29,24.3h440.6c13.4,0,24.29-10.9,24.29-24.3v-48.45
	C500.6,416.86,489.7,405.96,476.3,405.96z M286.69,160.8V75.22c0-0.84,0.71-1.54,1.54-1.54h146.99c0.84,0,1.54,0.71,1.54,1.54v85.58
	c0,0.83-0.71,1.54-1.54,1.54H288.23C287.4,162.34,286.69,161.64,286.69,160.8z M373.96,176.34v22.18H349.5v-22.18H373.96z
	 M104.74,42.54c1.26,1.1,2.89,1.73,4.6,1.73c2.36,0,4.55-1.19,5.85-3.15l8.13-12.38l8.13,12.38c1.29,1.97,3.49,3.15,5.85,3.15
	s4.55-1.19,5.85-3.16l8.13-12.37l8.13,12.37c1.29,1.97,3.49,3.15,5.85,3.15s4.55-1.19,5.85-3.15l8.13-12.38l8.13,12.38
	c1.29,1.97,3.49,3.15,5.85,3.15s4.55-1.19,5.85-3.15l8.13-12.37l8.13,12.37c1.29,1.97,3.49,3.16,5.85,3.16
	c1.71,0,3.34-0.63,4.6-1.73v138.35H104.74V42.54z M79.15,165.35h11.6v22.54c0,0,0,0,0,0s0,0,0,0c0,3.87,3.13,7,7,7h135.08
	c3.87,0,7-3.13,7-7c0,0,0,0,0,0s0,0,0,0v-22.54h11.6v45.09H79.15V165.35z M53.65,226.14c0-9.51,4.38-18.31,11.5-24.15v15.44
	c0,3.87,3.13,7,7,7h186.28c3.87,0,7-3.13,7-7v-22.54h70.08v10.63c0,3.87,3.13,7,7,7h38.46c3.87,0,7-3.13,7-7v-10.63h39.15
	c17.23,0,31.25,14.02,31.25,31.24v179.83H53.65V226.14z M486.6,478.7c0,5.68-4.62,10.3-10.29,10.3H35.7
	c-5.68,0-10.29-4.62-10.29-10.3v-48.45c0-5.68,4.62-10.29,10.29-10.29h440.6c5.68,0,10.29,4.62,10.29,10.29V478.7z M436.99,432.66
	H75.01c-3.87,0-7,3.13-7,7v29.65c0,3.87,3.13,7,7,7h361.97c3.87,0,7-3.13,7-7v-29.65C443.99,435.79,440.85,432.66,436.99,432.66z
	 M429.99,462.31H82.01v-15.65h347.97V462.31z M434.41,229.23H283.35c-3.87,0-7,3.13-7,7v151.06c0,3.87,3.13,7,7,7h151.06
	c3.87,0,7-3.13,7-7V236.23C441.41,232.37,438.28,229.23,434.41,229.23z M427.41,267h-23.76v-23.76h23.76V267z M365.88,267v-23.76
	h23.76V267H365.88z M389.65,281v23.76h-23.76V281H389.65z M351.88,267h-23.76v-23.76h23.76V267z M351.88,281v23.76h-23.76V281
	H351.88z M314.12,304.76h-23.76V281h23.76V304.76z M314.12,318.76v23.76h-23.76v-23.76H314.12z M328.12,318.76h23.76v23.76h-23.76
	V318.76z M351.88,356.53v23.76h-23.76v-23.76H351.88z M365.88,356.53h23.76v23.76h-23.76V356.53z M365.88,342.53v-23.76h23.76v23.76
	H365.88z M403.65,318.76h23.76v23.76h-23.76V318.76z M403.65,304.76V281h23.76v23.76H403.65z M314.12,243.23V267h-23.76v-23.76
	H314.12z M290.35,356.53h23.76v23.76h-23.76V356.53z M403.65,380.29v-23.76h23.76v23.76H403.65z M417.14,86.3H306.31
	c-3.87,0-7,3.13-7,7v49.42c0,3.87,3.13,7,7,7h110.83c3.87,0,7-3.13,7-7V93.3C424.14,89.44,421.01,86.3,417.14,86.3z M410.14,135.72
	h-96.83V100.3h96.83V135.72z M206.23,77.83c0,3.87-3.13,7-7,7h-75.33c-3.87,0-7-3.13-7-7s3.13-7,7-7h75.33
	C203.1,70.83,206.23,73.96,206.23,77.83z M206.23,110.92c0,3.87-3.13,7-7,7h-75.33c-3.87,0-7-3.13-7-7s3.13-7,7-7h75.33
	C203.1,103.92,206.23,107.06,206.23,110.92z M206.23,144.02c0,3.87-3.13,7-7,7h-75.33c-3.87,0-7-3.13-7-7s3.13-7,7-7h75.33
	C203.1,137.02,206.23,140.16,206.23,144.02z M256,241.47H79.05c-3.87,0-7,3.13-7,7v124.31c0,3.87,3.13,7,7,7H256c3.87,0,7-3.13,7-7
	V248.47C263,244.61,259.87,241.47,256,241.47z M249,365.78H86.05V255.47H249V365.78z"
            ></path>
        </SvgIcon>
    );
});

IconRegisters.displayName = 'IconRegisters';

export {IconRegisters};
