// Sentry
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing";

import React from "react";
import ReactDOM from "react-dom";
import Bootstrap from "./app/bootstrap";

Sentry.init({
    dsn: "https://4840eddb1d1a4b039e71e7feabac28d1@o649657.ingest.sentry.io/6370071",
    integrations: [new BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

ReactDOM.render(<React.StrictMode><Bootstrap /></React.StrictMode>, document.getElementById('app'));
