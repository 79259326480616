import React from "react"
import tw, {styled} from "twin.macro"

const OuterDiv = styled.div`
    ${tw`flex h-screen bg-gray-100`}
`

const InnerDiv = styled.div`
    ${tw`m-auto w-login max-w-screen-sm bg-white px-16 py-8 shadow-md rounded-md`}
`

type DivPros = {
    children?: React.ReactNode
}

const LoginLayout = ({children} : DivPros) =>{
    return (
        <OuterDiv>
            <InnerDiv>
                {children}
            </InnerDiv>
        </OuterDiv>
    )
}

export default LoginLayout