import * as React from 'react';

import SvgIcon, {SVGIconProps} from './svgIcon';

const IconProducts = React.forwardRef<SVGSVGElement, SVGIconProps>((props, ref) => {
    return (
        <SvgIcon {...props} ref={ref}>
            <path d="M11.499 12.03v11.971l-10.5-5.603v-11.835l10.5 5.467zm11.501 6.368l-10.501 5.602v-11.968l10.501-5.404v11.77zm-16.889-15.186l10.609 5.524-4.719 2.428-10.473-5.453 4.583-2.499zm16.362 2.563l-4.664 2.4-10.641-5.54 4.831-2.635 10.474 5.775z" />
        </SvgIcon>
    );
});

IconProducts.displayName = 'IconProducts';

export {IconProducts};
