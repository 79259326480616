import React, {Suspense} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {Loading} from 'Shared/feedback/Loading';
/**
 * Both Login and Wrapper needs to be out of Suspense
 * Wrapper has sidebar which loads with if kept inside suspense
 * TODO: try to use async to see if any effect in loading or file size happens or it gets reduced
 */
import Login from './views/auth/login';
import Wrapper from './views/layout/wrapper';
const Dashboard = React.lazy(() => import('./views/dashboard/index'));
const Products = React.lazy(() => import('./views/product/index'));
const ProductInstall = React.lazy(() => import('./views/product/generate'));
const ProductEdit = React.lazy(() => import('./views/product/edit'));

const AdditionalChargeInstall = React.lazy(
    () => import('./views/product/charges/generate')
);
const AdditionalCharges = React.lazy(() => import('./views/product/charges/index'));
const AdditionalChargeEdit = React.lazy(() => import('./views/product/charges/edit'));

const Categories = React.lazy(() => import('./views/product/category/index'));
const CategoryInstall = React.lazy(() => import('./views/product/category/generate'));
const CategoryEdit = React.lazy(() => import('./views/product/category/edit'));

const Coupons = React.lazy(() => import('./views/coupon/index'));
const CouponInstall = React.lazy(() => import('./views/coupon/generate'));
const CouponEdit = React.lazy(() => import('./views/coupon/edit'));

const Attributes = React.lazy(() => import('./views/product/attribute/index'));
const AttributeInstall = React.lazy(() => import('./views/product/attribute/generate'));
const AttributeEdit = React.lazy(() => import('./views/product/attribute/edit'));

const Receipts = React.lazy(() => import('./views/receipts/index'));
const ReceiptInstall = React.lazy(() => import('./views/receipts/generate'));

const Taxes = React.lazy(() => import('./views/tax/index'));
const TaxInstall = React.lazy(() => import('./views/tax/generate'));
const TaxEdit = React.lazy(() => import('./views/tax/edit'));

const Orders = React.lazy(() => import('./views/order/index'));
const ViewOrder = React.lazy(() => import('./views/order/single/index'));
const ReportsIndex = React.lazy(() => import('./views/reports/index'));

const RegistersIndex = React.lazy(() => import('./views/register/index'));
const RegisterInstall = React.lazy(() => import('./views/register/generate'));
const RegisterEdit = React.lazy(() => import('./views/register/edit'));

const OutletsIndex = React.lazy(() => import('./views/outlet/index'));
const OutletsInstall = React.lazy(() => import('./views/outlet/generate'));
const OutletEdit = React.lazy(() => import('./views/outlet/edit'));

const LabelInstall = React.lazy(() => import('./views/label/generate'));

const CustomersIndex = React.lazy(() => import('./views/user/index'));
const CustomerInstall = React.lazy(() => import('./views/user/generate'));
const CustomersOrders = React.lazy(() => import('./views/user/orders'));

const RewardsIndex = React.lazy(() => import('./views/reward/index'));
const RewardsInstall = React.lazy(() => import('./views/reward/generate'));
const RewardsSettings = React.lazy(() => import('./views/reward/settings/index'));

const ProductSections = React.lazy(() => import('./views/settings/products/sections'));

const SuppliersIndex = React.lazy(() => import('./views/supplier/index'));
const SupplierInstall = React.lazy(() => import('./views/supplier/generate'));
const SupplierEdit = React.lazy(() => import('./views/supplier/edit'));

const GoodsOrdersIndex = React.lazy(() => import('./views/reorder/index'));
const GoodsOrdersInstall = React.lazy(() => import('./views/reorder/generate'));
const GoodsOrdersEdit = React.lazy(() => import('./views/reorder/edit'));

import {AuthProvider} from 'hooks/useAuth';
import {PersonifyProvider} from 'Pos/context/personifyContext'; // TODO: get it out of POS directory

const Bootstrap = () => {
    return (
        <Router>
            <AuthProvider>
                <PersonifyProvider>
                    <Routes>
                        <Route path="login" element={<Login />} />
                        <Route path="/" element={<Wrapper />}>
                            <Route
                                path="dashboard"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <Dashboard />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="categories"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <Categories />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="categories/new"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <CategoryInstall />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="categories/:id/edit"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <CategoryEdit />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="coupons"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <Coupons />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="coupons/new"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <CouponInstall />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="coupons/:id/edit"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <CouponEdit />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="products"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <Products />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="products/new"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <ProductInstall />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="products/:id/edit"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <ProductEdit />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="products/additional-charges"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <AdditionalCharges />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="products/additional-charges/new"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <AdditionalChargeInstall />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="products/additional-charges/:id/edit"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <AdditionalChargeEdit />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="products/attributes"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <Attributes />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="products/attributes/new"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <AttributeInstall />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="products/attributes/:id/edit"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <AttributeEdit />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="receipts"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <Receipts />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="receipts/new"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <ReceiptInstall />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="taxes"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <Taxes />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="taxes/new"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <TaxInstall />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="taxes/:id/edit"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <TaxEdit />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="orders"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <Orders />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="orders/:id"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <ViewOrder />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="analytics"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <ReportsIndex />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="registers"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <RegistersIndex />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="registers/new"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <RegisterInstall />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="registers/:id/edit"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <RegisterEdit />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="outlets"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <OutletsIndex />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="outlets/new"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <OutletsInstall />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="outlets/:id/edit"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <OutletEdit />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="customers"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <CustomersIndex loadingType={`customers`} />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="customers/new"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <CustomerInstall loadingType={`customers`} />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="customers/:id/edit"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <CustomerInstall loadingType={`customers`} />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="users"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <CustomersIndex loadingType={`users`} />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="users/new"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <CustomerInstall loadingType={`users`} />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="users/:id/edit"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <CustomerInstall loadingType={`users`} />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="customers/:id/orders"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <CustomersOrders />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="printing-labels"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <LabelInstall />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="rewards"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <RewardsIndex />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="rewards/new"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <RewardsInstall />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="suppliers"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <SuppliersIndex />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="suppliers/new"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <SupplierInstall />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="suppliers/:id/edit"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <SupplierEdit />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="goods-orders"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <GoodsOrdersIndex />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="goods-orders/new"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <GoodsOrdersInstall />
                                    </Suspense>
                                }
                            />

                            <Route
                                path="goods-orders/:id/edit"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <GoodsOrdersEdit />
                                    </Suspense>
                                }
                            />

                            {/* Settings */}
                            <Route
                                path="/settings/rewards"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <RewardsSettings />
                                    </Suspense>
                                }
                            />

                            {/* Pizza/Salad/Burger Product Settings */}
                            <Route
                                path="/settings/products"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <ProductSections />
                                    </Suspense>
                                }
                            />
                        </Route>
                    </Routes>
                </PersonifyProvider>
            </AuthProvider>
        </Router>
    );
};

export default Bootstrap;
