import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { ChildrenProps } from "Types/child";

interface AuthContextInterface {
    user: string|[]|null;
    login: (data: {}, navigateTo: string) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextInterface | null>(null);

export const AuthProvider = ({ children } : ChildrenProps ) => {
  const [user, setUser] = useLocalStorage( "user", null );
  const navigate = useNavigate()

    const login = (data:{}, navigateTo: string) => {
    setUser(data);
    if( navigateTo !== `/dashboard` ){
        location.href = `${navigateTo}`
    }else {
        navigate( navigateTo, { replace: true } );
    }

  };

  const logout = () => {
    setUser(null);
    navigate( "/login", { replace: true } );
  };

  const value:AuthContextInterface = useMemo(
    () => ({
        user,
        login,
        logout
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
};

export const useAuth = () => {
  return useContext(AuthContext);
};
