import React from 'react';
import styled from '@emotion/styled';
import tw from 'twin.macro';

const Field = styled.div`
    ${tw`flex flex-col mb-4 relative`}
`;

type FieldContainerPros = {
    children?: React.ReactNode;
    styleClass?: string;
};

const FieldContainer = ({children, styleClass}: FieldContainerPros) => {
    return <Field className={`wellbeing ${styleClass}`}>{children}</Field>;
};

export default FieldContainer;
