// _id is a parameter replaced dynamically at runtime
// _slug is a parameter as well

export const Routes = {
    login: {
        token: `/sanctum/csrf-cookie`,
        auth: `/api/login`,
    },
    logout: `/api/logout`,
    auth: {
        user: `/api/user`,
    },
    dashboard: {
        index: `/api/dashboard`,
    },
    coupons: {
        index: `/api/coupons`,
        create: `/api/coupons/create`,
        view: `/api/coupons/_id/edit`,
    },
    categories: {
        count: `/api/categories/count`,
        index: `/api/categories`,
        create: `/api/categories/create`,
        view: `/api/categories/_id/edit`,
        edit: `/api/categories/_id/edit`,
        upload: `/api/categories/upload`,
        bulk: {
            index: `/api/categories/update`,
        },
        upindex: `/api/categories/list`,
    },
    customers: {
        index: `/api/customers`,
        view: `/api/customers/_id/edit`,
        create: `/api/customers/create`,
        update: `/api/customers/edit`,
        orders: `/api/customers/_id/orders`,
        comments: `/api/customers/_id/comments`,
        notes: {
            receipt: `/api/customers/_id/notes/_note`,
            index: `/api/customers/_id/notes`,
            create: `/api/customers/_id/notes`,
            delete: `/api/customers/_id/notes/_note`,
        },
        rewards: `/api/customers/_id/points`,
        bulk: {
            index: `/api/customers/update`,
        },
    },
    users: {
        index: `/api/users`,
        create: `/api/users/create`,
        update: `/api/users/edit`,
        types: {
            index: `/api/users/types`,
        },
    },
    taxes: {
        index: `/api/taxes`,
        create: `/api/taxes/create`,
        view: `/api/taxes/_id/edit`,
        edit: `/api/taxes/_id/edit`,
        bulk: {
            index: `/api/taxes/update`,
        },
    },
    products: {
        count: `/api/products/count`,
        index: `/api/products`,
        create: `/api/products/create`,
        view: `/api/products/_id/edit`,
        edit: `/api/products/_id/edit`,
        delete: `/api/products/_id`,
        upload: `/api/products/upload`,
        history: `/api/products/_id/history`,
        charges: {
            index: `/api/products/charges`,
            create: `/api/products/charges/create`,
            view: `/api/products/charges/_id/edit`,
            edit: `/api/products/charges/_id/edit`,
        },
        barcode: {
            create: `/api/products/barcode/create`,
        },
        bulk: {
            index: `/api/products/update`,
        },
        sync: {
            index: `/api/products/sync`,
        },
        images: {
            delete: `/api/products/images/_id`,
        },
    },
    attributes: {
        index: `/api/attributes`,
        create: `/api/attributes/create`,
        view: `/api/attributes/_id/edit`,
        edit: `/api/attributes/_id/edit`,
        options: {
            create: '/api/attributes/options/create',
            view: '/api/attributes/options/_id/edit',
            edit: '/api/attributes/options/_id/edit',
        },
    },
    orders: {
        count: `/api/orders/tally`,
        index: `/api/orders`,
        create: `/api/orders/create`,
        view: `/api/orders/_id`,
        refund: `/api/orders/_id/refunds`,
        receipt: `/api/orders/_id/receipt`,
        edit: `/api/orders/_id/edit`,
        delete: `/api/orders/_id`,
        notify: {
            email: `/api/orders/_id/notify`,
        },
        coupon: {
            apply: `/api/orders/apply-coupon`,
        },
        bulk: {
            index: `/api/orders/update`,
        },
    },
    outlets: {
        index: `/api/outlets`,
        create: `/api/outlets/create`,
        view: `/api/outlets/_id/edit`,
        edit: `/api/outlets/_id/edit`,
    },
    receipts: {
        index: `/api/receipts`,
        create: `/api/receipts/create`,
    },
    registers: {
        index: `/api/registers`,
        create: `/api/registers/create`,
        view: `/api/registers/_id/edit`,
        edit: `/api/registers/_id/edit`,
    },
    cashdrawer: {
        index: `/api/cashbox`,
        status: {
            view: `/api/cashbox/status`,
            create: `/api/cashbox/status/create`,
        },
        close: `/api/cashbox/_slug/close`,
    },
    reports: {
        index: `/api/analytics`,
        stats: `/api/analytics/stats`,
    },
    labels: {
        create: `/api/labels/create`,
    },

    rewards: {
        index: `/api/rewards`,
        create: `/api/rewards/create`,
        settings: {
            index: `/api/settings/rewards`,
            assignment: `/api/settings/rewards/points`,
            redemption: `/api/settings/rewards/redeem`,
        },
    },

    suppliers: {
        index: `/api/suppliers`,
        create: `/api/suppliers/create`,
        edit: `/api/suppliers/_id/edit`,
    },

    goodsorders: {
        index: `/api/goods-orders`,
        create: `/api/goods-orders/create`,
        edit: `/api/goods-orders/_id/edit`,
        delete: `/api/goods-orders/_id`,
        items: {
            index: `/api/goods-orders/_id/items`,
            create: `/api/goods-orders/_id/items/`, // used for creating and editing / updating items
        },
    },
};
