import React, {ComponentPropsWithRef, ElementType} from 'react';
import * as Styled from './Input.Style';

import styled from '@emotion/styled';

import {IconClear} from 'Shared/icons/iconClear';

type InputPros<T extends ElementType> = {
    isLoading?: boolean;
    disabled?: boolean;
    styleCss?: string;
    clear?: boolean;
} & ComponentPropsWithRef<T>;

const Span = styled.span``;

// const InputField = <T extends ElementType = 'input'>({
//     isLoading,
//     ...rest
// }: InputPros<T>): JSX.Element => {
const InputField = React.forwardRef(
    ({isLoading, clear, ...rest}: InputPros<ElementType>, ref) => {
        return (
            <Styled.InputContainer>
                <Styled.Input {...rest} ref={ref}></Styled.Input>
                {clear ? (
                    <Span>
                        <IconClear viewBox="0 0 24 24" size="24" />
                    </Span>
                ) : (
                    <></>
                )}
            </Styled.InputContainer>
        );
    }
);

export default InputField;
